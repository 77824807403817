<template>
  <div class="hk-product__wrapper">
    <hk-swiper :options="options" :index.sync="index">
      <hk-swiper-slide v-for="(product, i) in data" :key="product['id']">
        <div class="hk-product" :class="{'hk-product_active': active === i}" @click="active = i">
          <img :src="product.rectangle_img" />
          <div class="hk-card">
            <h4>{{ product['category_name'] }}</h4>
            <h3>{{ product['product_name'] }}</h3>
            <ul>
              <li
                v-for="(description, index) in product['introduce']"
                :key="index"
              >{{ description.length > 75 ? description.substring(0, 75) + '...' : description.substring(0, 75) }}</li>
            </ul>
            <div class="hk-product__button">
              <el-button @click="goDetail(product['id'])">{{ $t('common.detail') }}</el-button>
              <el-button @click="goPurchase(product.id)">{{ $t('common.purchase') }}</el-button>
            </div>
          </div>
        </div>
      </hk-swiper-slide>
    </hk-swiper>
    <div class="hk-swiper__prev hk-desktop" />
    <div class="hk-swiper__next hk-desktop" />

    <el-dialog :visible.sync="isDialogShow">
      <i class="iconfont icon-time" />
      <p>{{ $t('detail.outOfStock') }}</p>
      <el-form label-position="right">
        <el-form-item :label="$t('detail.stockAmount')" required>
          <el-input
            v-model="stockAmount"
            type="number"
            :placeholder="$t('detail.stockAmountDesc')"
          />
        </el-form-item>
      </el-form>
      <div class="hk-dialog__button">
        <el-button @click="isDialogShow = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="handleConfirm">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="reviewDialogShow">
      <p>{{ purchaseMessage }}</p>
      <div class="hk-alert__button">
        <el-button type="primary" @click="reviewDialogShow = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { HkSwiper, HkSwiperSlide } from '@/components/swiper'
export default {
  name: 'HkProduct',
  components: {
    HkSwiper,
    HkSwiperSlide,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      index: 1,
      active: 1,
      options: {
        grabCursor: true,
        keyboard: true,
        slidesPerView: 2,
        slidesOffsetBefore: -95,
        centeredSlides: true,
        simulateTouch: true,
        pagination: true,
        navigation: {
          prevEl: '.hk-swiper__prev',
          nextEl: '.hk-swiper__next',
        },
        breakpoints: {
          1280: {
            slidesPerView: 4,
            slidesOffsetBefore: -130,
          },
          1475: {
            slidesPerView: 4,
            slidesOffsetBefore: -140,
          },
          1675: {
            slidesPerView: 4,
            slidesOffsetBefore: -150,
          },
          1875: {
            slidesPerView: 4,
            slidesOffsetBefore: -160,
          },
        },
      },
      isDialogShow: false,
      stockAmount: 0,
      arrivalProductId: 0,
      reviewDialogShow: false,
      purchaseMessage: '',
    }
  },
  watch: {
    index(val) {
      this.active = val
    },
  },
  methods: {
    handleConfirm() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/product/arrivalNotice?lang=' +
        self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      formData = {
        product_id: self.arrivalProductId,
        num: parseInt(self.stockAmount),
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          switch (response.data.code) {
            case 0:
              self.isDialogShow = false
              self.purchaseMessage = self.$t('common.commandConfirm')
              self.reviewDialogShow = true
              break
            case 3007:
              self.isDialogShow = false
              self.purchaseMessage = response.data.msg
              self.reviewDialogShow = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    goDetail(id) {
      this.$store.commit('getProductId', id)
      this.$emit('detail', id)
    },
    goPurchase(num) {
      let self = this
      let purchaseItem = self.data.filter((item) => item.id == num)[0]
      if (purchaseItem.is_sale_out) {
        self.isDialogShow = true
        self.arrivalProductId = num
      } else {
        let rootApi = self.$store.state.config.apiRoot
        let apiCall = rootApi + '/api/shopping/create?'
        let header = {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        }
        let formData = new FormData()
        let productId = this.$router.history.current.query.id
        formData = {
          product_id: num,
          num: 1,
        }
        this.axios
          .post(apiCall, formData, header)
          .then(function (response) {
            let code = response.data.code
            switch (code) {
              case 2008:
                self.isDialogShow = true
                self.arrivalProductId = num
                break
              case 402:
                self.reviewDialogShow = true
                self.purchaseMessage = self.$t('common.underReview')
                break
              case 403:
                self.reviewDialogShow = true
                self.purchaseMessage = self.$t('login.loginFirst')
                break
              case 0:
                self.updateCart()
                self.reviewDialogShow = true
                self.purchaseMessage = self.$t('detail.addSuccess')
                break
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    },
    updateCart() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios.get(apiCall, header).then(function (response) {
        let num = response.data.data.product_num
        self.$store.commit('updateCartNum', num)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/index';
</style>
<style>
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
.hk-product img {
  filter: brightness(0.6);
}
</style>
