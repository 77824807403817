<template>
  <div>
    <hk-product
    :data="products"
    @detail="goDetail"
    @purchase="handlePurchase"
  />

  <el-dialog class="hk-alert" style="height: 500px;" :visible.sync="popupShow">
      <div class="pop_text" style="text-align: left;clear:both" v-html="popup.text"></div>
      <img style="width: 90%;" v-if="popup.img" :src="popup.img" />
      <!-- <div class="hk-alert__button">
        <el-button type="primary" @click="popupShow = false">{{ $t('common.confirm') }}</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import HkProduct from './swiper'
export default {
  name: 'HkHome',
  components: {
    HkProduct
  },
  data() {
//    this.getCategoryProduct();
    return {
      exportData: [],
      cateID : this.$router.history.current.query.category,
      popup: {
        text: '',
        img: ''
      },
      popupShow: false
    }
  },
  beforeRouteUpdate (to, from, next) {
  // just use `this`
  this.cateID = to.query.category;
  this.getCategoryProduct(this.cateID);
  next()
	},
  computed: {
    products () {
      return this.exportData
    },
  },
  created () {
    this.getCategoryProduct();
  },
  watch: {
    '$router': 'getCategoryProduct'
  },
  methods: {
    goDetail (id) {
      this.$router.push({
        name: 'detail',
        query: { id }
      })
    },
    handlePurchase (id) {
      //
    },
    getCategoryid () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/product/categorys';
      let formData = new FormData();
      formData = {
        lang: this.$store.state.config.language,
      }
      this.axios.get(apiCall, formData)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    async getCategoryProduct (cat_id) {
      try {
	    
	      let self = this;
	      let category_id = this.$router.history.current.query.category;
	      if( cat_id ) {
		     category_id =  cat_id;
        }
        if( !category_id ) {
          category_id = 1
        }
	      let rootApi = self.$store.state.config.apiRoot;
	      let apiCall = rootApi + '/api/product/showSeries?lang=' + self.$store.state.config.language + '&category_id=' + category_id;
	      let res = await this.axios.get(apiCall)
	      .then(function (response) {
	        self.exportData = [];
	        self.exportData = self.exportData.concat(response.data.data.list);
          self.popup = response.data.data.popup
          if (self.popup.text !== '' && !cat_id && self.$store.state.config.popupAlert) {
            self.popupShow = true
          }
	      })
	      .catch(function (error) {
	        console.log(error);
	      })
      } catch (ex) {
        console.log(ex);
      }
 
    },
  }
}
</script>

<style lang="scss" scoped>
.hk-product__wrapper::v-deep {
  @include mobile {
    .hk-swiper .hk-product.hk-product_active .hk-card {
      background: #383838;
    }
  }
}
</style>

<style>
.el-icon-close {
  display: inline-block !important;
}

.pop_text b {
  font-weight: bold !important;
}

.pop_text i {
    font-style: italic !important;
}
</style>